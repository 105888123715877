.returnToTop {
    margin-top: 48px;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    float: right;
    position: relative;
    margin-bottom: 48px;
    align-self: flex-end;
}

.returnToTop>svg {
    height: 24px;
    transform: scaleY(-1);
}

.returnToTop:hover{
   color: #F16729;
   cursor: pointer;
}

.returnToTop:hover g {
    fill: #F16729;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .returnToTop {
        font-weight: bold;
        display: flex;
        justify-content: flex-end;
        float: right;
        position: relative;
        margin-top: 48px;
    }

    .returnToTop:hover{
        color: initial;
        cursor: pointer;
     }
     
     .returnToTop:hover g {
         fill: initial;
         cursor: pointer;
     }
}