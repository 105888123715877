.interview {
    position: relative;
    display: block;
}

.hero {
    width: 100%;
    object-fit: cover;
    max-height: 80vh;
    z-index: -99;
}

.headers {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

.headers h1 {
    font-family: "acumin-pro";
    color: #ffffff;
    font-size: 100px;
    font-weight: 500;
    letter-spacing: -1.6px;
    line-height: 100px;
    text-align: center;
    z-index: 999;
    opacity: 1;
}

.headers h2 {
    color: #FFFFFF;
    font-family: "roboto-condensed";
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    margin-top: 40px;
    font-weight: 400;
    width: 66.666%;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
}

.content {
    margin: 60px 24px 0 24px;
}

.block1 {
    display: flex;
}

.block1>.text,.block1>.images {
    width: calc(50% - 12px);
}

.block1>.text{
    margin-right: 24px;
}

.block1>.images {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.block1 a {
    position: relative;
    text-decoration: underline;
    color: #181818;

}

.block1 img.hover {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    max-width: 50vw;
    margin-top: 0;
    transform: translateY(-100%);
    z-index: 10000;
}

.block1 a:hover {
    color: #F16729;
}

.text>p {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.18px;
    line-height: 26px;
    text-align: left;
    text-indent: 30px;
    color: #181818;
    position: relative;
}

.block1 img {
    width: 100%;
    margin-top: 24px;
}

.block2 {
    margin-top: 60px;
}

.twos {
    display: flex;
}

.twos>img {
    width: calc(50% - 12px);
    margin-top: 0;
    align-self: center;
}

.twos>*:first-child {
    margin-right: 24px;
}

.threes {
    display: flex;
    margin-top: 80px;
}

.threes>img {
    width: calc(33.333% - 16px);
    margin-right: 24px;
    align-self: center;
}

.block2 h5 {
    color: #181818;
    font-family: "acumin-pro";
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
    width: 83.33333%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    text-align: center;
    text-transform: uppercase;
}

.mobile {
    display: none;
}

@media only screen and (max-width: 768px) {
    .hero {
        max-height: 100%;
    }
    
    .headers h1 {
        font-size: 56px;
        line-height: 56px;
    }
    
    .headers h2 {
        font-size: 32px;
        line-height: 32px;
        width: calc(100% - 24px);
        margin-right: 12px;
        margin-left: 12px;
    }

    .content {
        margin-top: 40px;
    }

    .text>p {
        font-size: 16px;
        line-height: 24px;
    }

    .mobile {
        display: block;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    
    .twos {
        margin-top: 26px;
    }

    .twos>img {
        margin-right: 12px;
        width: calc(50% - 6px);
    }

    .twos>img:first-child {
        margin-right: 12px;
    }

    .block1>.images,.block2>.images,.block3>.images {
        display: none;
    }

    .block1>.text {
        width: 100%;
        margin-right: 0;
    }

    .block2 {
        margin-top: 40px;
        margin-right: 0;
        margin-bottom: -40px;
    }

    .block2>h5 {
        width: 100%;
        text-align: left;
        margin-top: 0;
        font-size: 24px;
        line-height: 30px;
    }

    .block2>img {
        object-fit: cover;
        align-items: center;
        width: 100%;
    }

    .threes {
        display: none;
    }

}

