.interview {
    padding-top: 24px;
    padding-bottom: 24px;
    font-family: "acumin-pro";
    margin-left: 24px;
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.interview h1 {
    margin: 0;
    padding: 0;
    font-size: 76px;
    line-height: 76px;
    margin-top: 24px;
    color: #5E5C5C;
    font-family: "roboto-condensed";
    font-weight: normal;
    letter-spacing: -2px
}

.interview h2 {
    margin: 0;
    padding: 0;
    font-family: "acumin-pro";
    letter-spacing: -0.47px;
    font-size: 28px;
    line-height: 36px;
    color: #5E5C5C;
    font-weight: 300;
    margin-top: 12px;
}

.interview__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 76px;
}

.interview__photos {
    width: calc(50% - 12px);
}

.interview__photos>img {
    width: 100%;
    height: auto;
    margin-top: 84px;
}

.interview__photos>img:first-child {
    margin-top: 0;
}

.interview__text {
    width: calc(50% - 12px);
}

.interview__text>p {
    text-align: left;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -.18px;
    color: #000000;
    font-weight: 300;
    margin-top: 40px;
    width: 83.3333%;
    float: right;
}

.interview__text>img {
    display: none;
}

.interview p:first-child{
    margin-top: 0;
}

.interview p.notre:before {
    content:"JV";
    position: absolute;
    transform: translateX(calc(-100% - 24px));
    font-family: "roboto-mono";
    font-weight: bold;
    color: #71994C;
    max-width: calc(8.3333% - 24px);
    text-align: right;
}

.interview p.notre.first:before {
    content:"JACOB VICTORINE";
}

.interview p.cn:before {
    content:"CN";
    position: absolute;
    transform: translateX(calc(-100% - 24px));
    font-family: "roboto-mono";
    font-weight: bold;
    max-width: calc(8.3333% - 24px);
    text-align: right;
}

.interview p.cn.first:before {
    content:"CARRIE NORMAN";
}

.interview p.tk:before {
    content:"TK";
    position: absolute;
    transform: translateX(calc(-100% - 24px));
    font-family: "roboto-mono";
    font-weight: bold;
    max-width: calc(8.3333% - 24px);
    text-align: right;
}

p.tk.first:before {
    content:"THOMAS KELLEY";
}

.interview p.ac:before {
    content:"AC";
    position: absolute;
    transform: translateX(calc(-100% - 24px));
    font-family: "roboto-mono";
    font-weight: bold;
    max-width: calc(8.3333% - 24px);
    text-align: right;
}

.interview p.ac.first:before {
    content: "ABIGAIL CHANG";
}


/* about section */

.about {
    position: relative;
    padding-top: 48px;
    margin-left: auto;
    margin-right: auto;
    width: 66.6666%;
    min-height: 100vh;
    height: auto;
    font-family: "roboto-mono";
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.2px;
}

.ispaSwoosh {
    position: absolute;
    left: -25%;
    top: 50%;
    transform: translateY(-50%);
    width: 150%;
    z-index: -5;
}

.about p {
    float: none;
    text-align: left;
    margin-top: 68px;
}

.about p>a {
    color: #181818;
}

.about p>a:hover {
    color: #F16729;
}

.header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.address>h3 {
    text-align: left;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
}

.address>h3:first-child{ 
    margin-bottom: 24px;
}

.subject {
    margin-top: 40px;
    text-align: left;
}

.subject>h4 {
    font-weight: normal;
}

.subject>h4:last-child {
    margin-top: 12px;
}

.about>.body {
    margin-top: 48px;
}

.body>p>h5 {
    font-family: "roboto-mono";
    margin-bottom: 24px;
}

.ispaDesc {
    display: flex;
    margin-top: 48px;
    text-align: left;
}

.ispaDesc>div {
    flex: 0 1 25%;
    margin-right: 24px;
}

.ispaDesc h5,.body h5 {
    font-family: "acumin-pro";
    font-size: 20px;
}

.ispaDesc p {
    font-family: "acumin-pro";
    font-weight: normal;
    margin-top: 24px;
}

.about .logo>img {
    height: 56px;
}

.signup {
    color: #181818;
    font-weight: bold;
    margin-top: 40px;
    display: block;
    text-align: left;
    text-decoration: none;
}

.signup:hover {
    color: #F16729;
}

@media only screen and (max-width: 768px) {
    .interview {
        margin-left: 24px;
        margin-right: 24px;
    }
    
    .interview h1 {
        font-size: 56px;
        line-height: 56px;
        margin-top: 0;
    }

    .interview h2 {
        font-size: 24px;
        line-height: 36px;
    }

    .interview__content {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: center;
        margin-top: 48px;
    }

    .interview__photos {
        display: none;
    }

    .interview__text,.interview__text>p {
        width: 100%;
        display: inline-block;
    }

    .interview__text>img {
        display: inline-block;
        margin-top: 40px;
        margin-bottom: 20px;
        width: 100%;
    }

    .interview p {
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        position: relative;
        margin-top: 56px;
    }

    .interview__text>p.tk,.interview__text>p.cn,.interview__text>p.ac,.interview__text>p.notre,.interview__text>p.nm {
        margin-top: 56px;
    }

    .interview p.notre:before {
        content:"JV";
        position: absolute;
        top: -1.5em;
        transform: none;
        font-family: "roboto-mono";
        font-weight: bold;
    }
    
    .interview p.tk:before {
        content:"TK";
        position: absolute;
        top: -1.5em;
        transform: none;
        font-family: "roboto-mono";
        font-weight: bold;
    }

    .interview p.cn:before {
        content:"CN";
        position: absolute;
        top: -1.5em;
        transform: none;
        font-family: "roboto-mono";
        font-weight: bold;
    }

    .interview p.ac:before {
        content:"AC";
        position: absolute;
        top: -1.5em;
        transform: none;
        font-family: "roboto-mono";
        font-weight: bold;
    }

    .interview p.first:before {
        max-width: 100%;
    }

    .interview__photos>img {
        margin-top: 24px;
    }

    .about {
        padding: 24px;
        padding-top: 48px;
        display: block;
        width: 100%;
        min-height: 100vh;
        height: auto;
        font-size: 16px;
        line-height: 20px;
    }
    
    .about p {
        font-size: 16px;
        line-height: 20px;
        float: none;
        width: 100%;
        margin-top: 24px;
    }

    .about p>h5 {
        font-family: "roboto-mono";
        margin-top: 40px;
        font-size: 16px;
    }

    .address>h3 {
        text-align: left;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
    }
    
    .address>h3:first-child{ 
        margin-bottom: 24px;
    }

    .about .logo>img {
        height: 25px;
    }

    .subject {
        text-align: left;
    }
    
    .subject>h4 {
        font-weight: normal;

    }
    
    .subject>h4:last-child {
        margin-top: 12px;
    }

    .about>.body {
        margin-top: 40px;
    }

    .ispaDesc {
        flex-wrap: wrap;
    }

    .ispaDesc>div {
        flex: 0 1 calc(50% - 12px);
        margin-right: 24px;
    }

    .ispaDesc>div:nth-child(2n) {
        margin-right: 0;
    }
    
    .ispaDesc h5 {
        font-family: "acumin-pro";
        font-size: 16px;
    }
    
    .ispaDesc p {
        font-family: "acumin-pro";
        font-weight: normal;
        margin-top: 24px;
        margin-bottom: 40px;
    }

    .ispaSwoosh {
        width: 150%;
        left: -25%;
        transform: rotate(270deg);
    }
}