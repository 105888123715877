.recap>* {
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header {
    margin-top: 24px;
    height: 84px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    display: none;
}

.header>img:first-child {
    margin-top: 0;
    height: 16px;
    width: auto;
    object-fit: contain;
}

.header>img:last-child {
    margin-top: 12px;
    height: 36px;
    width: auto;
    object-fit: contain;
}

.gallery {
    width: calc(100% - 48px);
    height: calc(100% - 144px);
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.gallery img, .gallery p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    user-select: none;
}

.gallery>p {
    font-size: 24px;
    line-height: 36px;
    font-family: "roboto-mono";
    text-align: left;
}

.gallery>.summary {
    font-size: 18px;
    line-height: 28px;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
}

.gallery>p>a {
    color: #181818;
    cursor: pointer;
    text-align: left;
    font-size: 18px;
}

.galleryOverlay {
    width: 100%;
    height: calc(100% - 144px);
    position: absolute;
}

.galleryOverlay>.left,.galleryOverlay>.right {
    width: 50%;
    height: 100%;
    display: inline-block;
}

.left {
    cursor: url('../assets/arrow-left.svg') 16 16, pointer;
}

.right {
    cursor: url('../assets/arrow-right.svg') 16 16, pointer;
}

.title {
    font-family: "roboto-mono";
    font-size: 18px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: rotate(-90deg) translateY(150%);
    text-align: start;
    
}

.counter,.pdf {
    font-family: "roboto-mono";
    font-size: 20px;
    line-height: 32px;
    position: absolute;
    right: 24px;
    top: 24px;
    text-align: right;
}

.pdf {
    left: 24px;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
    color: #181818;
}

.pdf:hover {
    color: #F16729;
}


.buttons {
    font-family: "roboto-mono";
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    text-align: center;
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.buttons>*:first-child {
    padding-right: 24px;
    display: inline-block;
    border-right: 1px solid #181818;
}

.buttons>*:last-child {
    display: inline-block;
    padding-left: 24px;
}

.buttons>*:hover {
    cursor: pointer;
    color: #F16729;
}

@media only screen and (max-width: 768px) {
    
    .gallery>.arrowLeft {
        position: absolute;
        transform: rotate(180deg) translateY(18px);
        left: -48px;
        height: 36px;
        width: auto;
    }
    
    .gallery>.arrowRight {
        position: absolute;
        right: -64px;
        height: 36px;
        width: auto;
        left: initial;
    }

    .gallery svg {
        width: 36px;
        height: 36px;
    }

    .gallery>p {
        font-size: 18px;
        line-height: 28px;
        width: calc(100% - 48px);
    }

    .gallery>p>a {
        font-size: 16px;
    }

    .gallery>.summary {
        font-size: 16px;
        line-height: 20px;
        padding-left: 12px;
        padding-right: 12px;
        max-height: 75vh;
        overflow: scroll;
    }

    .counter,.pdf {
        font-family: "roboto-mono";
        font-size: 18px;
        line-height: 24px;
        position: absolute;
        right: 24px;
        top: 24px;
        text-align: right;
    }

    .pdf {
        left: 24px;
        text-align: left;
        text-decoration: underline;
        cursor: pointer;
        color: #181818;
    }
    
    .pdf:hover {
        color: initial;
    }

    .buttons {
        font-size: 14px;
        line-height: 20px;
        width: 100%;
    }
    
    .buttons>*:first-child {
        padding-right: 12px;
    }
    
    .buttons>*:last-child {
        padding-left: 12px;
    }

    .buttons>*:hover {
        cursor: initial;
        color: initial;
    }
} 