@import url(//hello.myfonts.net/count/30f520);
@import url(https://use.typekit.net/dny2phk.css);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "acumin-pro", Helvetica, sans-serif;
  letter-spacing: -.018em;
  font-size: 14px;
  line-height: 24px;
  color: #181818;
  background-color: #fff;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-overflow-scrolling: touch;
}

p {
  margin: 0px;
}

html,body
{
  margin: 0;
  padding: 0;
}

header {
    height: 100vh;
    background-color: #F0F1F3;
    background-size: 20px 20px;
    background-image: -webkit-linear-gradient(left, white 1px, transparent 1px), -webkit-linear-gradient(top, white 1px, transparent 1px);
    background-image: linear-gradient(to right, white 1px, transparent 1px), linear-gradient(to bottom, white 1px, transparent 1px);
    padding-top: 24px;
    padding-bottom: 24px;
    width: 100%;
    overflow: scroll;
}

.Nav_header__logos__2a92e{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Nav_header__logos__2a92e>img:first-child {
    margin-top: 0;
    height: 24px;
    width: auto;
    object-fit: contain;
}

.Nav_header__logos__2a92e>img:last-child {
    margin-top: 12px;
    height: 48px;
    width: auto;
    object-fit: contain;
}

.Nav_header__nav__1uzQY {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-left: 25%;
    margin-right: calc(8.3333% + 48px);
    text-align: left;
    margin-bottom: 40px;
}

.Nav_header__nav__1uzQY li {
    margin-top: 60px;
    position: relative;
    display: block;
}

.Nav_header__nav__1uzQY h1 {
    font-family: "roboto-condensed";
    display: inline-block;
    position: relative;
    font-size: 60px;
    line-height: 60px;
    margin: 0;
    font-weight: normal;
    text-align: left;
    z-index: 5;
}

.Nav_header__nav__1uzQY a {
    text-decoration: none;
    color: #5e5c5c;
}

.Nav_header__nav__1uzQY svg {
    position: relative;
    display: inline;
    top: 6px;
    right: -10px;
    height: 50px;
}


svg g {
    fill: #5e5c5c;
}

.Nav_header__nav__1uzQY span.Nav_arrow__1H08n {
    position: relative;
    display: inline;
    top: 8x;
    left: 12px;
    font-size: 58px;
    line-height: 58px;
    font-family: "HiraginoSans-W4, Hiragino Sans";
}

li:hover h1 {
    cursor: default;
}

li:hover h1:not(.Nav_disabled__9ED2u) {
    color: #F16729;
    cursor: pointer;
}

li:hover g {
    fill: #F16729;
}

.Nav_disabled__9ED2u {
    color: #ACACAC;
}

.Nav_header__nav__1uzQY span {
    position: absolute;
    top: .2em;
    left: 0;
    font-family: "roboto-mono";
    font-size: 20px;
    color: #363636;
    vertical-align: top;
    margin-right: 8px;
    cursor: default;
    -webkit-transform: translateX(calc(-100% - 8px));
            transform: translateX(calc(-100% - 8px));
    z-index: 5;
}

.Nav_header__nav__1uzQY img {
    position: absolute;
    left: 45%;
    top: -3em;
    max-width: 50vh;
    max-height: 50vh;
    overflow: hidden;
}

.Nav_header__nav__1uzQY img.Nav_leftImagePlacement__3nMS4 {
    left: 25%;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}

.Nav_header__nav__1uzQY img.Nav_disabledImage__1GJi8 {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}

@media only screen and (max-width: 768px) {
    header {
        min-height: 100vh;
        height: auto;
    }
    
    .Nav_header__nav__1uzQY {
        margin-left: 24px;
        margin-right: 96px;
    }

    .Nav_header__nav__1uzQY h1 {
        font-size: 32px;
        line-height: 32px;
        text-align: left;
        white-space: normal;
        display: inline;
    }

    .Nav_header__nav__1uzQY li {
        margin-top: 48px;
        display: block;
    }
    
    .Nav_header__nav__1uzQY span {
        left: 2px;
        top: -14px;
        font-size: 12px;
        line-height: 12px;
        -webkit-transform: none;
                transform: none;
    }

    .Nav_header__nav__1uzQY svg {
        white-space: nowrap;
        height: 25px;
        width: 25px;
        right: -6px;
        top: 2px;
    }

}
.Nicole_interview__3OkOG {
    position: relative;
    display: block;
}

.Nicole_hero__1GVFO {
    width: 100%;
    object-fit: cover;
    max-height: 80vh;
    z-index: -99;
}

.Nicole_headers__3VBRF {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

.Nicole_headers__3VBRF h1 {
    font-family: "acumin-pro";
    color: #ffffff;
    font-size: 100px;
    font-weight: 500;
    letter-spacing: -1.6px;
    line-height: 100px;
    text-align: center;
    z-index: 999;
    opacity: 1;
}

.Nicole_headers__3VBRF h2 {
    color: #FFFFFF;
    font-family: "roboto-condensed";
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    margin-top: 40px;
    font-weight: 400;
    width: 66.666%;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
}

.Nicole_content__2tOzn {
    margin: 60px 24px 0 24px;
}

.Nicole_block1__a_ZVp {
    display: flex;
}

.Nicole_block1__a_ZVp>.Nicole_text__SHdly,.Nicole_block1__a_ZVp>.Nicole_images__2_orm {
    width: calc(50% - 12px);
}

.Nicole_block1__a_ZVp>.Nicole_text__SHdly{
    margin-right: 24px;
}

.Nicole_block1__a_ZVp>.Nicole_images__2_orm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Nicole_block1__a_ZVp a {
    position: relative;
    text-decoration: underline;
    color: #181818;

}

.Nicole_block1__a_ZVp img.Nicole_hover__3-pHT {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    max-width: 50vw;
    margin-top: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    z-index: 10000;
}

.Nicole_block1__a_ZVp a:hover {
    color: #F16729;
}

.Nicole_text__SHdly>p {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.18px;
    line-height: 26px;
    text-align: left;
    text-indent: 30px;
    color: #181818;
    position: relative;
}

.Nicole_block1__a_ZVp img {
    width: 100%;
    margin-top: 24px;
}

.Nicole_block2__2mIkP {
    margin-top: 60px;
}

.Nicole_twos__29i9v {
    display: flex;
}

.Nicole_twos__29i9v>img {
    width: calc(50% - 12px);
    margin-top: 0;
    align-self: center;
}

.Nicole_twos__29i9v>*:first-child {
    margin-right: 24px;
}

.Nicole_threes__3pw7C {
    display: flex;
    margin-top: 80px;
}

.Nicole_threes__3pw7C>img {
    width: calc(33.333% - 16px);
    margin-right: 24px;
    align-self: center;
}

.Nicole_block2__2mIkP h5 {
    color: #181818;
    font-family: "acumin-pro";
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
    width: 83.33333%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    text-align: center;
    text-transform: uppercase;
}

.Nicole_mobile__1EH-O {
    display: none;
}

@media only screen and (max-width: 768px) {
    .Nicole_hero__1GVFO {
        max-height: 100%;
    }
    
    .Nicole_headers__3VBRF h1 {
        font-size: 56px;
        line-height: 56px;
    }
    
    .Nicole_headers__3VBRF h2 {
        font-size: 32px;
        line-height: 32px;
        width: calc(100% - 24px);
        margin-right: 12px;
        margin-left: 12px;
    }

    .Nicole_content__2tOzn {
        margin-top: 40px;
    }

    .Nicole_text__SHdly>p {
        font-size: 16px;
        line-height: 24px;
    }

    .Nicole_mobile__1EH-O {
        display: block;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    
    .Nicole_twos__29i9v {
        margin-top: 26px;
    }

    .Nicole_twos__29i9v>img {
        margin-right: 12px;
        width: calc(50% - 6px);
    }

    .Nicole_twos__29i9v>img:first-child {
        margin-right: 12px;
    }

    .Nicole_block1__a_ZVp>.Nicole_images__2_orm,.Nicole_block2__2mIkP>.Nicole_images__2_orm,.Nicole_block3__38xW->.Nicole_images__2_orm {
        display: none;
    }

    .Nicole_block1__a_ZVp>.Nicole_text__SHdly {
        width: 100%;
        margin-right: 0;
    }

    .Nicole_block2__2mIkP {
        margin-top: 40px;
        margin-right: 0;
        margin-bottom: -40px;
    }

    .Nicole_block2__2mIkP>h5 {
        width: 100%;
        text-align: left;
        margin-top: 0;
        font-size: 24px;
        line-height: 30px;
    }

    .Nicole_block2__2mIkP>img {
        object-fit: cover;
        align-items: center;
        width: 100%;
    }

    .Nicole_threes__3pw7C {
        display: none;
    }

}


.ReturnToTop_returnToTop__Uh5kK {
    margin-top: 48px;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    float: right;
    position: relative;
    margin-bottom: 48px;
    align-self: flex-end;
}

.ReturnToTop_returnToTop__Uh5kK>svg {
    height: 24px;
    -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
}

.ReturnToTop_returnToTop__Uh5kK:hover{
   color: #F16729;
   cursor: pointer;
}

.ReturnToTop_returnToTop__Uh5kK:hover g {
    fill: #F16729;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .ReturnToTop_returnToTop__Uh5kK {
        font-weight: bold;
        display: flex;
        justify-content: flex-end;
        float: right;
        position: relative;
        margin-top: 48px;
    }

    .ReturnToTop_returnToTop__Uh5kK:hover{
        color: #000;
        color: initial;
        cursor: pointer;
     }
     
     .ReturnToTop_returnToTop__Uh5kK:hover g {
         fill: initial;
         cursor: pointer;
     }
}
.Recap_recap__kBNiv>* {
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Recap_header__1wYo3 {
    margin-top: 24px;
    height: 84px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    display: none;
}

.Recap_header__1wYo3>img:first-child {
    margin-top: 0;
    height: 16px;
    width: auto;
    object-fit: contain;
}

.Recap_header__1wYo3>img:last-child {
    margin-top: 12px;
    height: 36px;
    width: auto;
    object-fit: contain;
}

.Recap_gallery__WuCb7 {
    width: calc(100% - 48px);
    height: calc(100% - 144px);
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.Recap_gallery__WuCb7 img, .Recap_gallery__WuCb7 p {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.Recap_gallery__WuCb7>p {
    font-size: 24px;
    line-height: 36px;
    font-family: "roboto-mono";
    text-align: left;
}

.Recap_gallery__WuCb7>.Recap_summary__3v2K- {
    font-size: 18px;
    line-height: 28px;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
}

.Recap_gallery__WuCb7>p>a {
    color: #181818;
    cursor: pointer;
    text-align: left;
    font-size: 18px;
}

.Recap_galleryOverlay__1j1lY {
    width: 100%;
    height: calc(100% - 144px);
    position: absolute;
}

.Recap_galleryOverlay__1j1lY>.Recap_left__3ULDC,.Recap_galleryOverlay__1j1lY>.Recap_right__CuP1Q {
    width: 50%;
    height: 100%;
    display: inline-block;
}

.Recap_left__3ULDC {
    cursor: url(/static/media/arrow-left.eadae7e1.svg) 16 16, pointer;
}

.Recap_right__CuP1Q {
    cursor: url(/static/media/arrow-right.b3dfc639.svg) 16 16, pointer;
}

.Recap_title__UZ1Ac {
    font-family: "roboto-mono";
    font-size: 18px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: rotate(-90deg) translateY(150%);
            transform: rotate(-90deg) translateY(150%);
    text-align: start;
    
}

.Recap_counter__2ixNc,.Recap_pdf__11tYh {
    font-family: "roboto-mono";
    font-size: 20px;
    line-height: 32px;
    position: absolute;
    right: 24px;
    top: 24px;
    text-align: right;
}

.Recap_pdf__11tYh {
    left: 24px;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
    color: #181818;
}

.Recap_pdf__11tYh:hover {
    color: #F16729;
}


.Recap_buttons__3XuYg {
    font-family: "roboto-mono";
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    text-align: center;
    position: absolute;
    bottom: 24px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Recap_buttons__3XuYg>*:first-child {
    padding-right: 24px;
    display: inline-block;
    border-right: 1px solid #181818;
}

.Recap_buttons__3XuYg>*:last-child {
    display: inline-block;
    padding-left: 24px;
}

.Recap_buttons__3XuYg>*:hover {
    cursor: pointer;
    color: #F16729;
}

@media only screen and (max-width: 768px) {
    
    .Recap_gallery__WuCb7>.Recap_arrowLeft__2Y8DO {
        position: absolute;
        -webkit-transform: rotate(180deg) translateY(18px);
                transform: rotate(180deg) translateY(18px);
        left: -48px;
        height: 36px;
        width: auto;
    }
    
    .Recap_gallery__WuCb7>.Recap_arrowRight__13DAc {
        position: absolute;
        right: -64px;
        height: 36px;
        width: auto;
        left: auto;
        left: initial;
    }

    .Recap_gallery__WuCb7 svg {
        width: 36px;
        height: 36px;
    }

    .Recap_gallery__WuCb7>p {
        font-size: 18px;
        line-height: 28px;
        width: calc(100% - 48px);
    }

    .Recap_gallery__WuCb7>p>a {
        font-size: 16px;
    }

    .Recap_gallery__WuCb7>.Recap_summary__3v2K- {
        font-size: 16px;
        line-height: 20px;
        padding-left: 12px;
        padding-right: 12px;
        max-height: 75vh;
        overflow: scroll;
    }

    .Recap_counter__2ixNc,.Recap_pdf__11tYh {
        font-family: "roboto-mono";
        font-size: 18px;
        line-height: 24px;
        position: absolute;
        right: 24px;
        top: 24px;
        text-align: right;
    }

    .Recap_pdf__11tYh {
        left: 24px;
        text-align: left;
        text-decoration: underline;
        cursor: pointer;
        color: #181818;
    }
    
    .Recap_pdf__11tYh:hover {
        color: #000;
        color: initial;
    }

    .Recap_buttons__3XuYg {
        font-size: 14px;
        line-height: 20px;
        width: 100%;
    }
    
    .Recap_buttons__3XuYg>*:first-child {
        padding-right: 12px;
    }
    
    .Recap_buttons__3XuYg>*:last-child {
        padding-left: 12px;
    }

    .Recap_buttons__3XuYg>*:hover {
        cursor: auto;
        cursor: initial;
        color: #000;
        color: initial;
    }
} 
.Interview_interview__39RtG {
    padding-top: 24px;
    padding-bottom: 24px;
    font-family: "acumin-pro";
    margin-left: 24px;
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Interview_interview__39RtG h1 {
    margin: 0;
    padding: 0;
    font-size: 76px;
    line-height: 76px;
    margin-top: 24px;
    color: #5E5C5C;
    font-family: "roboto-condensed";
    font-weight: normal;
    letter-spacing: -2px
}

.Interview_interview__39RtG h2 {
    margin: 0;
    padding: 0;
    font-family: "acumin-pro";
    letter-spacing: -0.47px;
    font-size: 28px;
    line-height: 36px;
    color: #5E5C5C;
    font-weight: 300;
    margin-top: 12px;
}

.Interview_interview__content__t7S9M {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 76px;
}

.Interview_interview__photos__VQoWF {
    width: calc(50% - 12px);
}

.Interview_interview__photos__VQoWF>img {
    width: 100%;
    height: auto;
    margin-top: 84px;
}

.Interview_interview__photos__VQoWF>img:first-child {
    margin-top: 0;
}

.Interview_interview__text__6CUeS {
    width: calc(50% - 12px);
}

.Interview_interview__text__6CUeS>p {
    text-align: left;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -.18px;
    color: #000000;
    font-weight: 300;
    margin-top: 40px;
    width: 83.3333%;
    float: right;
}

.Interview_interview__text__6CUeS>img {
    display: none;
}

.Interview_interview__39RtG p:first-child{
    margin-top: 0;
}

.Interview_interview__39RtG p.Interview_notre__zrhUl:before {
    content:"JV";
    position: absolute;
    -webkit-transform: translateX(calc(-100% - 24px));
            transform: translateX(calc(-100% - 24px));
    font-family: "roboto-mono";
    font-weight: bold;
    color: #71994C;
    max-width: calc(8.3333% - 24px);
    text-align: right;
}

.Interview_interview__39RtG p.Interview_notre__zrhUl.Interview_first__2UyNC:before {
    content:"JACOB VICTORINE";
}

.Interview_interview__39RtG p.Interview_cn__3ucMb:before {
    content:"CN";
    position: absolute;
    -webkit-transform: translateX(calc(-100% - 24px));
            transform: translateX(calc(-100% - 24px));
    font-family: "roboto-mono";
    font-weight: bold;
    max-width: calc(8.3333% - 24px);
    text-align: right;
}

.Interview_interview__39RtG p.Interview_cn__3ucMb.Interview_first__2UyNC:before {
    content:"CARRIE NORMAN";
}

.Interview_interview__39RtG p.Interview_tk__2ztxY:before {
    content:"TK";
    position: absolute;
    -webkit-transform: translateX(calc(-100% - 24px));
            transform: translateX(calc(-100% - 24px));
    font-family: "roboto-mono";
    font-weight: bold;
    max-width: calc(8.3333% - 24px);
    text-align: right;
}

p.Interview_tk__2ztxY.Interview_first__2UyNC:before {
    content:"THOMAS KELLEY";
}

.Interview_interview__39RtG p.Interview_ac__hCHiu:before {
    content:"AC";
    position: absolute;
    -webkit-transform: translateX(calc(-100% - 24px));
            transform: translateX(calc(-100% - 24px));
    font-family: "roboto-mono";
    font-weight: bold;
    max-width: calc(8.3333% - 24px);
    text-align: right;
}

.Interview_interview__39RtG p.Interview_ac__hCHiu.Interview_first__2UyNC:before {
    content: "ABIGAIL CHANG";
}


/* about section */

.Interview_about__DLocw {
    position: relative;
    padding-top: 48px;
    margin-left: auto;
    margin-right: auto;
    width: 66.6666%;
    min-height: 100vh;
    height: auto;
    font-family: "roboto-mono";
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.2px;
}

.Interview_ispaSwoosh__MscyS {
    position: absolute;
    left: -25%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 150%;
    z-index: -5;
}

.Interview_about__DLocw p {
    float: none;
    text-align: left;
    margin-top: 68px;
}

.Interview_about__DLocw p>a {
    color: #181818;
}

.Interview_about__DLocw p>a:hover {
    color: #F16729;
}

.Interview_header__7gKl9 {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.Interview_address__1E9LU>h3 {
    text-align: left;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
}

.Interview_address__1E9LU>h3:first-child{ 
    margin-bottom: 24px;
}

.Interview_subject__2LY4u {
    margin-top: 40px;
    text-align: left;
}

.Interview_subject__2LY4u>h4 {
    font-weight: normal;
}

.Interview_subject__2LY4u>h4:last-child {
    margin-top: 12px;
}

.Interview_about__DLocw>.Interview_body__1Cq2h {
    margin-top: 48px;
}

.Interview_body__1Cq2h>p>h5 {
    font-family: "roboto-mono";
    margin-bottom: 24px;
}

.Interview_ispaDesc__2Ypu5 {
    display: flex;
    margin-top: 48px;
    text-align: left;
}

.Interview_ispaDesc__2Ypu5>div {
    flex: 0 1 25%;
    margin-right: 24px;
}

.Interview_ispaDesc__2Ypu5 h5,.Interview_body__1Cq2h h5 {
    font-family: "acumin-pro";
    font-size: 20px;
}

.Interview_ispaDesc__2Ypu5 p {
    font-family: "acumin-pro";
    font-weight: normal;
    margin-top: 24px;
}

.Interview_about__DLocw .Interview_logo__2LmgB>img {
    height: 56px;
}

.Interview_signup__3w0cB {
    color: #181818;
    font-weight: bold;
    margin-top: 40px;
    display: block;
    text-align: left;
    text-decoration: none;
}

.Interview_signup__3w0cB:hover {
    color: #F16729;
}

@media only screen and (max-width: 768px) {
    .Interview_interview__39RtG {
        margin-left: 24px;
        margin-right: 24px;
    }
    
    .Interview_interview__39RtG h1 {
        font-size: 56px;
        line-height: 56px;
        margin-top: 0;
    }

    .Interview_interview__39RtG h2 {
        font-size: 24px;
        line-height: 36px;
    }

    .Interview_interview__content__t7S9M {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: center;
        margin-top: 48px;
    }

    .Interview_interview__photos__VQoWF {
        display: none;
    }

    .Interview_interview__text__6CUeS,.Interview_interview__text__6CUeS>p {
        width: 100%;
        display: inline-block;
    }

    .Interview_interview__text__6CUeS>img {
        display: inline-block;
        margin-top: 40px;
        margin-bottom: 20px;
        width: 100%;
    }

    .Interview_interview__39RtG p {
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        position: relative;
        margin-top: 56px;
    }

    .Interview_interview__text__6CUeS>p.Interview_tk__2ztxY,.Interview_interview__text__6CUeS>p.Interview_cn__3ucMb,.Interview_interview__text__6CUeS>p.Interview_ac__hCHiu,.Interview_interview__text__6CUeS>p.Interview_notre__zrhUl,.Interview_interview__text__6CUeS>p.Interview_nm__2FUQt {
        margin-top: 56px;
    }

    .Interview_interview__39RtG p.Interview_notre__zrhUl:before {
        content:"JV";
        position: absolute;
        top: -1.5em;
        -webkit-transform: none;
                transform: none;
        font-family: "roboto-mono";
        font-weight: bold;
    }
    
    .Interview_interview__39RtG p.Interview_tk__2ztxY:before {
        content:"TK";
        position: absolute;
        top: -1.5em;
        -webkit-transform: none;
                transform: none;
        font-family: "roboto-mono";
        font-weight: bold;
    }

    .Interview_interview__39RtG p.Interview_cn__3ucMb:before {
        content:"CN";
        position: absolute;
        top: -1.5em;
        -webkit-transform: none;
                transform: none;
        font-family: "roboto-mono";
        font-weight: bold;
    }

    .Interview_interview__39RtG p.Interview_ac__hCHiu:before {
        content:"AC";
        position: absolute;
        top: -1.5em;
        -webkit-transform: none;
                transform: none;
        font-family: "roboto-mono";
        font-weight: bold;
    }

    .Interview_interview__39RtG p.Interview_first__2UyNC:before {
        max-width: 100%;
    }

    .Interview_interview__photos__VQoWF>img {
        margin-top: 24px;
    }

    .Interview_about__DLocw {
        padding: 24px;
        padding-top: 48px;
        display: block;
        width: 100%;
        min-height: 100vh;
        height: auto;
        font-size: 16px;
        line-height: 20px;
    }
    
    .Interview_about__DLocw p {
        font-size: 16px;
        line-height: 20px;
        float: none;
        width: 100%;
        margin-top: 24px;
    }

    .Interview_about__DLocw p>h5 {
        font-family: "roboto-mono";
        margin-top: 40px;
        font-size: 16px;
    }

    .Interview_address__1E9LU>h3 {
        text-align: left;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
    }
    
    .Interview_address__1E9LU>h3:first-child{ 
        margin-bottom: 24px;
    }

    .Interview_about__DLocw .Interview_logo__2LmgB>img {
        height: 25px;
    }

    .Interview_subject__2LY4u {
        text-align: left;
    }
    
    .Interview_subject__2LY4u>h4 {
        font-weight: normal;

    }
    
    .Interview_subject__2LY4u>h4:last-child {
        margin-top: 12px;
    }

    .Interview_about__DLocw>.Interview_body__1Cq2h {
        margin-top: 40px;
    }

    .Interview_ispaDesc__2Ypu5 {
        flex-wrap: wrap;
    }

    .Interview_ispaDesc__2Ypu5>div {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: calc(50% - 12px);
        margin-right: 24px;
    }

    .Interview_ispaDesc__2Ypu5>div:nth-child(2n) {
        margin-right: 0;
    }
    
    .Interview_ispaDesc__2Ypu5 h5 {
        font-family: "acumin-pro";
        font-size: 16px;
    }
    
    .Interview_ispaDesc__2Ypu5 p {
        font-family: "acumin-pro";
        font-weight: normal;
        margin-top: 24px;
        margin-bottom: 40px;
    }

    .Interview_ispaSwoosh__MscyS {
        width: 150%;
        left: -25%;
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg);
    }
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

