header {
    height: 100vh;
    background-color: #F0F1F3;
    background-size: 20px 20px;
    background-image: linear-gradient(to right, white 1px, transparent 1px), linear-gradient(to bottom, white 1px, transparent 1px);
    padding-top: 24px;
    padding-bottom: 24px;
    width: 100%;
    overflow: scroll;
}

.header__logos{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header__logos>img:first-child {
    margin-top: 0;
    height: 24px;
    width: auto;
    object-fit: contain;
}

.header__logos>img:last-child {
    margin-top: 12px;
    height: 48px;
    width: auto;
    object-fit: contain;
}

.header__nav {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-left: 25%;
    margin-right: calc(8.3333% + 48px);
    text-align: left;
    margin-bottom: 40px;
}

.header__nav li {
    margin-top: 60px;
    position: relative;
    display: block;
}

.header__nav h1 {
    font-family: "roboto-condensed";
    display: inline-block;
    position: relative;
    font-size: 60px;
    line-height: 60px;
    margin: 0;
    font-weight: normal;
    text-align: left;
    z-index: 5;
}

.header__nav a {
    text-decoration: none;
    color: #5e5c5c;
}

.header__nav svg {
    position: relative;
    display: inline;
    top: 6px;
    right: -10px;
    height: 50px;
}


svg g {
    fill: #5e5c5c;
}

.header__nav span.arrow {
    position: relative;
    display: inline;
    top: 8x;
    left: 12px;
    font-size: 58px;
    line-height: 58px;
    font-family: "HiraginoSans-W4, Hiragino Sans";
}

li:hover h1 {
    cursor: default;
}

li:hover h1:not(.disabled) {
    color: #F16729;
    cursor: pointer;
}

li:hover g {
    fill: #F16729;
}

.disabled {
    color: #ACACAC;
}

.header__nav span {
    position: absolute;
    top: .2em;
    left: 0;
    font-family: "roboto-mono";
    font-size: 20px;
    color: #363636;
    vertical-align: top;
    margin-right: 8px;
    cursor: default;
    transform: translateX(calc(-100% - 8px));
    z-index: 5;
}

.header__nav img {
    position: absolute;
    left: 45%;
    top: -3em;
    max-width: 50vh;
    max-height: 50vh;
    overflow: hidden;
}

.header__nav img.leftImagePlacement {
    left: 25%;
    transform: translateX(-100%);
}

.header__nav img.disabledImage {
    filter: grayscale(100%);
}

@media only screen and (max-width: 768px) {
    header {
        min-height: 100vh;
        height: auto;
    }
    
    .header__nav {
        margin-left: 24px;
        margin-right: 96px;
    }

    .header__nav h1 {
        font-size: 32px;
        line-height: 32px;
        text-align: left;
        white-space: normal;
        display: inline;
    }

    .header__nav li {
        margin-top: 48px;
        display: block;
    }
    
    .header__nav span {
        left: 2px;
        top: -14px;
        font-size: 12px;
        line-height: 12px;
        transform: none;
    }

    .header__nav svg {
        white-space: nowrap;
        height: 25px;
        width: 25px;
        right: -6px;
        top: 2px;
    }

}