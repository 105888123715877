@import url("//hello.myfonts.net/count/30f520");
@import url("https://use.typekit.net/dny2phk.css");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "acumin-pro", Helvetica, sans-serif;
  letter-spacing: -.018em;
  font-size: 14px;
  line-height: 24px;
  color: #181818;
  background-color: #fff;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-overflow-scrolling: touch;
}

p {
  margin: 0px;
}

html,body
{
  margin: 0;
  padding: 0;
}
